//@import url('https://fonts.cdnfonts.com/css/avenir-lt-pro');
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('assets/font/AvenirNextLTPro-Regular.ttf');
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-image: url('images/kollektion.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  .top {
    height: 50px;
    background-color: #ffffff;
    padding: 5px 0;
    .logo-container {
      height: 50px;
    }
    .logo {
      display: flex;
      align-self: flex-start;
      justify-self: flex-start;
      height: 40px;
    }
  }
  .App-header {
    display: flex;
    flex-direction: column;
    height: 210px;

    justify-content: center;
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      h1 {
        color: #fff;
        border-bottom: 8px solid #fff;
      }
      .login {
        padding: 5px 40px;
        h2 {
          color: #fff;
          font-size: 26px;
        }
      }
      @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h1,
        .login {
          width: 75%;
          min-height: 50%;
          padding: 20px;
          margin: 5px 0;
          h2 {
            width: auto;
            margin: 0 0 15px;
          }
        }
        h1 {
          background-color: rgba(0, 0, 0, 0.5);
          border: 0;
          margin: 0;
        }
      }
      @media only screen and (max-width: 500px) {
        h1,
        .login {
          width: 80%;
        }
      }
    }
  }
  .container {
    max-width: 1100px;
    margin: 15px auto;
    border-radius: 100px;
    color: black;
    background-color: white;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .col {
        width: 30%;
        h2 {
          display: flex;
          align-items: flex-end;
          height: 40px;
          text-transform: uppercase;
          font-size: 18px;
          border-bottom: 6px solid black;
          padding-bottom: 7px;
          margin: 40px 0 8px;
        }
        p {
          margin: 0 0 10px;
        }
      }
      @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .col {
          width: 80%;
        }
      }
      @media only screen and (max-width: 500px) {
        .col {
          width: 90%;
        }
      }
    }
  }
  a {
    color: #00B2A9;
  }
  .button {
    font-weight: bold;
    background: #00B2A9;
    border-radius: 19px;
    color: #fff;
    padding: 0.5rem 1rem;
    margin: auto;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
  footer {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    min-width:100%;
    display: flex;
    font-size: 14px;
    color: #333;
    @media only screen and (max-width: 500px) {
      height: 60px;
    }
  }
}


body {
  font-family: 'Avenir Next LT Pro', 'sans-serif';
  margin: 0;
  padding: 0;
  background: url('images/kollektion.jpg') no-repeat center center fixed;
  background-size: cover;
}

.top-bar {
  width: 1200px;
  display: flex;
  justify-content: start;
  align-items: center;
}

header{
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 10px;
  width: 100%;
  background-color: white;
}

.logo {
  height: 45px;
}

.logo img {
  height: 50%;
  margin: 10px;
}

.app-title {
  font-weight: bold;
  color: black;
  margin-right: 10px;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px); /* Adjust based on header height */
  color: black;
}

.content {
  background: white;
  padding: 1rem 1rem 1rem 1rem;
  max-width: 600px;
  text-align: left;

  position: relative;
  display: -moz-flex;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.04), 0 0.4px 0.9px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-radius: 19px;
  color: #000;
}

h1 {
  font-family: 'Avenir Next LT Pro', 'sans-serif';
  font-weight: bold;
  font-size: 1.5em;
  letter-spacing: 0.1px;
  color: black;
}

p {
  color: black;
}

button:hover {
  background-color: #008080;
}

a {
  color: #00B2A9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  //background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: #3095B4;
  text-align: center;
  padding: 10px;
  z-index: 1000; /* Ensures it is on top of other elements */
}

.row {
  display: flex;
  .login-button-row {
    
  }
}
.col-right {
  display: flex;
  flex-flow: column;
  flex: auto;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.col-left {
  justify-content: space-between;
}

.app-title {
  margin-left: 20px;
}

.divider {
  margin-left: 15px;
}